import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import DesignedForRealtors from "../components/Repeating/DesignedForRealtors";
import AccelerateYourBusiness from "../components/Repeating/AccelerateYourBusiness";
import Testimonials from "../components/Repeating/Testimonials";
import Reviews from "../components/Repeating/Reviews";
import RecentPosts from "../components/Repeating/RecentPosts";
import SliderLogos1 from "../components/Slider/SliderLogos1";
import SliderLogos2 from "../components/Slider/SliderLogos2";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";
import HomeIntro from "../components/Repeating/HomeIntro";
import ThreeColumnSection from "../components/Repeating/ThreeColumnSection";
import HomeStopLosingLeads from "../components/Repeating/HomeStopLosingLeads";
import MonitorAgentPerformance from "../components/Repeating/MonitorAgentPerformance";
import ReviewsGrid from "../components/Repeating/ReviewsGrid";
import TrustedBy from "../components/Repeating/TrustedBy";

const annualPlan = {
  label: "Pay Monthly",
  id: "annual",
  pricingCards: [
    {
      billingDetails: "Billed annually (save $288/yr)",
      name: "Agent",
      normalPrice: "Normally $123/mo",
      price: "$99 / mo",
      additional: "20% Off Per User Annually",
      url: "/free-trial",
      list: (
        <ul className="pricingList">
          <li> 1 user</li>
          <li>1 CRM connection</li>
          <li>2 app integrations</li>
          <li>
            Free personalized onboarding with dedicated implementation support
          </li>
          <li>Agent/owner accountability dashboard</li>
          <li>Actionable lead source ROI data</li>
          <li>Automated data import</li>
          <li>Real-time leaderboard display</li>
          <li>Profit and loss + income data</li>
          <li>Owner-only data view</li>
          <li>Live Customer Support (not a robot)</li>
        </ul>
      ),
    },
    {
      billingDetails: "Billed annually (save $444/yr)",
      name: "Team",
      normalPrice: "Normally $186/mo",
      price: "$149 / mo",
      additional: "20% Off Per User Annually",
      url: "/free-trial",
      list: (
        <ul className="pricingList">
          <li>
            <strong>Multi-users</strong>
          </li>
          <li>
            <strong>Unlimited CRM connections</strong>
          </li>
          <li>
            <strong>Unlimited app integrations</strong>
          </li>
          <li>
            Free personalized onboarding with dedicated implementation support
          </li>
          <li>Agent/owner accountability dashboard</li>
          <li>Actionable lead source ROI data</li>
          <li>Automated data import</li>
          <li>Real-time leaderboard display</li>
          <li>
            <strong>Live weekly training</strong>
          </li>
          <li>Profit and loss + income data</li>
          <li>Owner-only data view</li>
          <li>
            <strong>Exclusive custom service prices</strong>
          </li>
          <li>Live Customer Support (not a robot)</li>
        </ul>
      ),
    },
  ],
};

const monthlyPlan = {
  label: "Monthly Plans",
  id: "monthly",
  pricingCards: [
    {
      billingDetails: "Billed annually (save $288/yr)",
      name: "Agent",
      normalPrice: "",
      price: "$123 / mo",
      additional: "Additional users $20/mo",
      url: "/free-trial",
      list: (
        <ul className="pricingList">
          <li> 1 user</li>
          <li>1 CRM connection</li>
          <li>2 app integrations</li>
          <li>
            Free personalized onboarding with dedicated implementation support
          </li>
          <li>Agent/Owner Accountability Dashboard</li>
          <li>Actionable lead source ROI data</li>
          <li>Automated data import</li>
          <li>Real-time leaderboard display</li>
          <li>Profit and loss + income data</li>
          <li>Owner-only data view</li>
          <li>Live Customer Support (not a robot)</li>
        </ul>
      ),
    },
    {
      billingDetails: "Billed annually (save $444/yr)",
      name: "Team",
      normalPrice: "",
      price: "$186 / mo",
      additional: "Additional users $20/mo",
      url: "/free-trial",
      list: (
        <ul className="pricingList">
          <li>
            <strong>Multi-Users</strong>
          </li>
          <li>
            <strong>Unlimited CRM connections</strong>
          </li>
          <li>
            <strong>Unlimited app integrations</strong>
          </li>
          <li>
            Free personalized onboarding with dedicated implementation support
          </li>
          <li>Agent/owner accountability dashboard</li>
          <li>Actionable lead source ROI data</li>
          <li>Automated data import</li>
          <li>Real-time leaderboard display</li>
          <li>
            <strong>Live weekly training</strong>
          </li>
          <li>Profit and loss + income data</li>
          <li>Owner-only data view</li>
          <li>
            <strong>Exclusive custom service prices</strong>
          </li>
          <li>Live Customer Support (not a robot)</li>
        </ul>
      ),
    },
  ],
};

const faqs = [
  {
    question: "What does onboarding look like?",
    answer: (
      <p>
        From a personalized session with a dedicated Support Specialist to data
        migration and a comprehensive product demo, we'll ensure a smooth
        transition. Here's what to expect:
        <ol className="ml-4 mt-3 list-decimal">
          <li>One-hour Zoom call with our Support Specialist</li>
          <li>Establish connections and integrations for your systems</li>
          <li>Migrate data into our dashboard</li>
          <li>Demo of our entire product</li>
          <li>Review of account pricing</li>
          <li>Recommendations for other connections as needed</li>
          <li>Continued support for easy transitioning</li>
        </ol>
      </p>
    ),
  },
  {
    question: "When is your customer support team available?",
    answer: (
      <p>
        Our company's customer support hours are from 8 a.m. to 6 p.m. CST,
        Monday through Friday. During these hours, our dedicated team is
        available to assist you promptly and efficiently with any inquiries or
        assistance you may need.
      </p>
    ),
  },
  {
    question: "Do you offer custom pricing for enterprise clients?",
    answer: (
      <p>
        Yes, we offer custom pricing tailored specifically for enterprise
        clients. Please reach out to us.
      </p>
    ),
  },
  {
    question: "Can I upgrade or downgrade my plan at any time?",
    answer: (
      <p>
        Our plans are designed to be flexible, allowing you to upgrade or
        downgrade to fit your needs. Please note that if you opt for an annual
        payment plan, any changes will take effect at the start of the next
        billing cycle to ensure a seamless transition.
      </p>
    ),
  },
];

const Page = ({ data }) => {
  const [activePlan, setActivePlan] = useState("monthly");
  const HeadingTag = "h2";
  return (
    <Layout
      backgroundColor="#f1eefb"
      customTheme="light-pink"
      noHeaderBottomSpacing={true}
    >
      <SearchEngineOptimization
        title="Our Plans | Realsynch"
        description="We have plans to support all businesses, whether you’re a small real estate team, a growing firm, or a large realty company. Book a demo today, and let us show you how Realsynch can completely transform your business."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className={"mt-[64px] lg:mt-[64px]"}>
        <div className="container">
          <div className="flex flex-col items-center justify-center gap-y-16">
            <div className="flex w-full flex-col items-center justify-center">
              <HeadingTag>Plans</HeadingTag>
              <p>
                We have plans to support all businesses, whether you’re a small
                real estate team, a growing firm, or a large realty company.
              </p>
            </div>
            {/* <div className="grid w-full max-w-[440px] grid-cols-2">
              <div
                className={`${
                  activePlan === annualPlan.id
                    ? "bg-brand-gradient-2"
                    : "bg-[#f1eefb]"
                } cursor-pointer pb-[2px]`}
              >
                <div
                  className={`${
                    activePlan === annualPlan.id ? "text-black" : "text-[#666]"
                  } flex w-full flex-col items-center justify-center bg-[#f1eefb] py-4 font-heading text-[18px] font-semibold lg:w-[240px]`}
                  onClick={() => setActivePlan("annual")}
                >
                  Pay Monthly
                  <div className="font-heading text-sm leading-[20px]">
                    Commit Annually
                  </div>
                </div>
              </div>
              <div
                className={`${
                  activePlan === monthlyPlan.id
                    ? "bg-brand-gradient-2"
                    : "bg-[#f1eefb]"
                } cursor-pointer pb-[2px]`}
              >
                <div
                  className={`${
                    activePlan === monthlyPlan.id ? "text-black" : "text-[#666]"
                  } flex w-full flex-col items-center justify-center bg-[#f1eefb] py-4 font-heading text-[18px] font-semibold lg:w-[240px]`}
                  onClick={() => setActivePlan("monthly")}
                >
                  Pay Upfront
                  <div className="font-heading text-sm leading-[20px]">
                    Commit Annually
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col gap-6 lg:flex-row">
              {activePlan == "monthly" &&
                monthlyPlan.pricingCards.map((plan, index) => (
                  <PricingCard pricingObject={plan} key={index} />
                ))}{" "}
              {activePlan == "annual" &&
                annualPlan.pricingCards.map((plan, index) => (
                  <PricingCard pricingObject={plan} key={index} />
                ))}
            </div>
            <div className="flex w-full max-w-[440px] gap-x-2 rounded-[16px] bg-brand-gradient-1 p-[2px] lg:max-w-[904px]">
              <div className="flex w-full flex-col justify-between rounded-[14px] bg-white p-10 lg:flex-row">
                <div className="flex flex-col gap-y-4">
                  <div className="font-heading text-[32px] font-bold leading-[40px] text-[#1a1a1a]">
                    Enterprise Brokerage Plan
                  </div>
                  <div>
                    Book a call for a demo and to learn about our custom
                    pricing.
                  </div>
                </div>
                <ButtonSolid
                  href="/free-trial"
                  text="Start Free Trial"
                  altStyle={2}
                  className="mx-auto mt-4 ml-auto w-full lg:mr-0 lg:w-fit"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FAQSection faqs={faqs} />
    </Layout>
  );
};

const FAQSection = ({ faqs }) => {
  const [activeCardIndex, setActiveCardIndex] = useState(null);

  return (
    <section className="mx-auto mt-20 mb-[104px] max-w-[800px] px-4 lg:px-0">
      <div className="pb-10 font-heading text-[48px] font-extrabold text-[#1A1A1A]">
        FAQs
      </div>
      <div className="border-t-[1px] border-[#CCC] ">
        {faqs.map((faq, index) => (
          <FAQCard
            object={faq}
            index={index}
            cardIndex={index}
            activeCardIndex={activeCardIndex}
            setActiveCardIndex={setActiveCardIndex}
          />
        ))}
      </div>
    </section>
  );
};

const FAQCard = ({
  object,
  activeCardIndex,
  cardIndex,
  setActiveCardIndex,
}) => {
  const handleClick = () => {
    if (cardIndex === activeCardIndex) {
      setActiveCardIndex(null);
    } else {
      setActiveCardIndex(cardIndex);
    }
  };

  return (
    <div className="border-b-[1px] border-[#CCCCCC]">
      <div className="flex w-full justify-between py-10" onClick={handleClick}>
        <div className="font-heading text-[20px] font-semibold text-[#1A1A1A]">
          {object.question}
        </div>
        <i
          className={`far fa-chevron-down ml-1 transform text-sm transition-all duration-300 ease-linear ${
            activeCardIndex === cardIndex ? "rotate-180" : "rotate-0"
          }`}
        ></i>
      </div>
      {activeCardIndex === cardIndex && <div>{object.answer}</div>}
    </div>
  );
};

const PricingCard = ({ pricingObject }) => {
  return (
    <div className="flex max-w-[440px] flex-col items-center rounded-[16px] bg-white p-10 drop-shadow-md">
      {/* <div className="rounded-[24px] bg-brand-gradient-1 p-3 font-semibold text-white">
        {pricingObject.billingDetails}
      </div> */}
      <div className="mt-4 font-heading text-[32px] font-bold leading-[40px] text-[#1a1a1a]">
        {pricingObject.name}
      </div>
      {/* <div className="mt-2 font-heading font-normal text-[#666]">
        {pricingObject.normalPrice}
      </div> */}
      {/* <div className="mt-2 bg-brand-gradient bg-clip-text font-heading text-[40px] font-extrabold leading-[44px] text-transparent">
        {pricingObject.price}
      </div>
      <div className="my-10 w-full border-y-[1px] border-[#ccc] py-6 text-center font-heading leading-[24px] text-[#666]">
        {pricingObject.additional}
      </div> */}
      <div className="my-10 h-[1px] w-full bg-[#CCC]"></div>
      <div className="mb-10 [&>ul>li>strong]:bg-brand-gradient-2 [&>ul>li>strong]:bg-clip-text [&>ul>li>strong]:text-transparent">
        {pricingObject.list}
      </div>
      <ButtonSolid
        href={pricingObject.url}
        text="Start Free Trial"
        altStyle={2}
        className="mx-auto mt-auto w-full"
      />
    </div>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
  }
`;

export default Page;
